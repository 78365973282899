<template>
  <v-app>
    <center v-if="!isBusy && !sittingDetails">
      <p class="blue-grey--text text--lighten-1 my-6">
        <fa icon="info-circle" /> <strong>Sorry.</strong> There are no details
        for this sitting.
      </p>
    </center>

    <v-card v-if="sittingDetails" class="d-flex pa-4 mb-4">
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">Sitting ID</small
        ><br />{{ sittingDetails.sitting_id }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">User Id</small><br />{{
          sittingDetails.user_id
        }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">Sitting</small><br />{{
          sittingDetails.status
        }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">Grading</small><br />{{
          sittingDetails.grading_status
        }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">Assessment</small
        ><br />{{ sittingDetails.assessment_type_name }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">Score</small><br />{{
          sittingDetails.score.englishscore
        }}
      </div>
      <div class="mr-8">
        <small class="blue-grey--text text--lighten-1">CEFR</small><br />{{
          sittingDetails.score.cefr
        }}
      </div>
      <div
        class="mr-8"
        v-for="breakdown in Object.keys(sittingDetails.score.breakdown)"
        :key="breakdown"
      >
        <small class="blue-grey--text text--lighten-1 text-capitalize">{{
          breakdown
        }}</small
        ><br /><span class="text-capitalize">{{
          sittingDetails.score.breakdown[breakdown]
        }}</span>
      </div>
      <div class="ml-auto mr-2">
        <small class="blue-grey--text text--lighten-1"><!--Proctoring--></small
        ><br />
        <v-btn
          title="Proctor sittings for this user"
          dark
          color="teal"
          small
          :to="{
            path: '/proctoring',
            query: { search: sittingDetails.user_id }
          }"
        >
          <v-icon small>mdi-image-multiple</v-icon>
        </v-btn>
      </div>
      <div class="mr-2">
        <small class="blue-grey--text text--lighten-1"><!--Events--></small
        ><br />
        <v-btn
          title="View the User Events for this sitting"
          dark
          color="accent"
          small
          :to="{
            path: '/user-events',
            query: {
              uid: sittingDetails.user_id,
              ed: eventDate(sittingDetails.started_time)
            }
          }"
        >
          <v-icon small>mdi-account-group</v-icon>
        </v-btn>
      </div>
      <div>
        <small class="blue-grey--text text--lighten-1"><!--Assessment--></small
        ><br />
        <v-btn
          title="View the Assessment Instance for this sitting"
          dark
          color="indigo"
          small
          :to="{
            path: `/assessment-instances/${sittingDetails.assessment_instance_id}/items`
          }"
        >
          <v-icon small>mdi-view-list-outline</v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-card class="mb-6">
      <v-data-table
        fixed-header
        height="68vh"
        loading-text="Loading responses ..."
        no-data-text="No responses found."
        :headers="itemHeaders"
        :items="sittingDetails ? sittingDetails.items : []"
        :items-per-page="100"
        :loading="isBusy"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        :footer-props="{ 'disable-items-per-page': true }"
      >
        <template v-slot:[`item.response`]="{ item }">
          <span>{{ item.response.join(",") }}</span>
        </template>
        <template v-slot:[`item.grader_type`]="{ item }">
          <span>{{ item.grader_type.join(", ") }}</span>
        </template>
        <template v-slot:[`item.data_points`]="{ item }">
          <table v-if="item.data_points">
            <tr
              v-for="(data_point, index) in item.data_points"
              :key="`data_point_${index}`"
            >
              <td class="text-no-wrap pa-0 pr-4">
                <v-btn
                  class="mr-1"
                  :title="
                    `View the user that submitted this response (${data_point.grader_id})`
                  "
                  small
                  :to="{
                    path: '/users',
                    query: { search: data_point.grader_id }
                  }"
                >
                  <v-icon small>mdi-account</v-icon>
                </v-btn>
                {{ data_point.type }}
              </td>
              <td class="pa-0">{{ data_point.value }}</td>
            </tr>
          </table>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <es-btn
              title="Copy Question ID to clipboard"
              class="es-btn mr-2"
              color="primary"
              @click="copyToClipboard(item.question_id)"
            >
              <fa icon="copy" />
            </es-btn>
            <v-btn
              title="Edit this item"
              dark
              color="teal"
              small
              :to="{ path: `/items/update/${item.item_id}` }"
            >
              <v-icon small>mdi-view-list-outline</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import * as clipboardy from "clipboardy";

export default {
  name: "SittingDetail",
  computed: {
    ...mapGetters("sittings", [
      "isBusy",
      "itemHeaders",
      "sittingDetails",
      "isSpeakingSitting"
    ])
  },
  methods: {
    ...mapActions("sittings", [
      "fetchSittingDetailsByID",
      "resetSittingDetails"
    ]),
    eventDate(date) {
      return DateTime.fromISO(date).toFormat("yyyy,MM,dd");
    },
    async copyToClipboard(string) {
      await clipboardy.write(string);
      this.$store.dispatch("snackbar/snack", {
        mode: "success",
        message: `✅ Copied to clipboard: <strong class="px-4">${string}</strong>`
      });
    }
  },
  beforeMount() {
    const { sittingId } = this.$route.params;
    if (!sittingId) {
      return this.$router.push("/dashboard/sittings");
    }
    this.fetchSittingDetailsByID({ sittingId });
  },
  beforeDestroy() {
    this.resetSittingDetails();
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      &:first-child {
        padding-left: 34px;
        background-color: inherit;
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
  .v-data-footer__select {
    visibility: hidden;
  }
  .v-btn.v-size--small {
    min-width: 0;
    padding: 0px 8px;
  }
}

.actions {
  margin-right: -20px;
}
.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
  &:disabled {
    opacity: 0.2;
  }
}
.sitting-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1em;
  & .sitting-info-item {
    margin-right: 1em;
    &:not(:last-child) {
      &::after {
        content: "|";
        margin-left: 1em;
      }
    }
    & span {
      margin-right: 1em;
    }
  }
}
.audio-player {
  border-radius: 10px;
  width: 100%;
}
.inline-table {
  & tr {
    & td {
      padding: 0 !important;
    }
  }
}
</style>
